export const dataExportHeaders = [
  { label: 'Type', key: 'app_type' },
  { label: 'App Id', key: 'app_id' },
  { label: 'Title', key: 'name' },
  { label: 'App Website', key: 'app_website' },
  { label: 'Time Played (minutes)', key: 'playtime_forever' },
  {
    label: 'Time To Beat 100% (minutes)',
    key: 'minutes_to_beat_completionist',
  },
  {
    label: 'Time To Beat Main Game (minutes)',
    key: 'minutes_to_beat_main_game',
  },
  { label: 'Regular Price (USD)', key: 'store_price_default_usd' },
  { label: 'Metascore', key: 'metascore' },
  { label: 'Metascore Link', key: 'metascore_link' },
  { label: 'Release Date', key: 'release_date' },
  { label: 'Developers', key: 'developers' },
  { label: 'Publishers', key: 'publishers' },
  { label: 'Windows', key: 'os_windows' },
  { label: 'Mac', key: 'os_mac' },
  { label: 'Linux', key: 'os_linux' },
  { label: 'Install Size (MB)', key: 'size_mb' },
  { label: 'Controller Support', key: 'controller_support' },
  { label: 'Has Multiplayer', key: 'multiplayer' },
  { label: 'Genres', key: 'genres' },
  { label: 'Has Steam Cloud', key: 'steamcloud_enabled' },
  { label: 'Has Achievements', key: 'achievements_enabled' },
  { label: 'Has Trading Cards', key: 'tradingcards_enabled' },
  { label: 'Has Steam Workshop', key: 'workshop_enabled' },
  { label: 'Has Stats', key: 'stats_enabled' },
  { label: 'Has HDR', key: 'hdr' }
];
